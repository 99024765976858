import React from "react";
import { Link } from "gatsby";

import styles from "./Logo.module.css";

const Logo = () => (
  <Link to="/" className={styles.Logo}>
    <div className={styles.LogoDesktop}>
      <svg
        width="163"
        height="44"
        viewBox="0 0 163 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.0928 44C19.0976 44.007 16.1321 43.4084 13.3741 42.24C10.7197 41.1184 8.31229 39.485 6.28913 37.433L10.46 29.681C10.7825 29.1039 11.2533 28.6234 11.8238 28.2893C12.3943 27.9553 13.044 27.7798 13.7051 27.781H17.564L20.1729 22.748H16.817C16.6552 22.7484 16.4964 22.7063 16.356 22.6259C16.2156 22.5455 16.0987 22.4297 16.0172 22.29C15.9335 22.1514 15.8877 21.9934 15.8843 21.8316C15.881 21.6697 15.9203 21.5099 15.9981 21.368L17.6758 18.385C17.7574 18.2386 17.8768 18.1165 18.0211 18.0311C18.1653 17.9457 18.3295 17.9001 18.4971 17.899H25.8233C25.9772 17.899 26.1283 17.9372 26.2637 18.0101C26.3992 18.0831 26.5146 18.1885 26.5992 18.317C26.6852 18.4465 26.7377 18.5954 26.752 18.7502C26.7663 18.905 26.7421 19.0609 26.6812 19.204L20.3409 33.373H31.92L34.1749 28.153L28.5631 28.135L34.5631 13.442C34.6579 13.2156 34.6952 12.9692 34.6715 12.7249C34.6477 12.4805 34.5634 12.2459 34.4268 12.042C34.2882 11.8347 34.1006 11.6649 33.8804 11.5479C33.6602 11.4308 33.4145 11.37 33.1651 11.371H16.3301C15.8395 11.3738 15.358 11.5044 14.9332 11.7499C14.5083 11.9954 14.1548 12.3473 13.9073 12.771L2.57429 32.4C0.882735 29.2279 -0.00153168 25.688 6.93191e-05 22.093C-0.00722461 19.1908 0.561165 16.316 1.67243 13.635C2.7837 10.954 4.41589 8.52002 6.47419 6.47401C8.52023 4.41575 10.9543 2.78376 13.6353 1.67249C16.3163 0.561228 19.1907 -0.00723147 22.0928 7.2365e-06C24.9952 -0.00719815 27.8702 0.561295 30.5513 1.67255C33.2325 2.78381 35.6667 4.41577 37.713 6.47401C39.7714 8.51993 41.4038 10.9539 42.5152 13.6349C43.6266 16.3159 44.1952 19.1908 44.1881 22.093C44.1982 24.9819 43.6306 27.8436 42.5186 30.51C41.4067 33.1763 39.7727 35.5934 37.713 37.619C33.5569 41.7309 27.9391 44.0257 22.0928 44Z"
          fill="#7CDEF4"
        />
        <path
          d="M70.6428 15.027H63.0918L62.3648 19.11H68.2188L67.1938 24.927H61.3398L59.4568 35.61H51.9238L56.7338 8.29602H71.9658L70.6428 15.027Z"
          fill="#101113"
        />
        <path
          d="M77.4674 35.61H69.6924L74.8004 8.29602H81.9784L77.4674 35.61Z"
          fill="#101113"
        />
        <path
          d="M101.574 14.933H96.1857L92.5317 35.609H84.9997L88.6537 14.933H83.8057L84.8497 8.29602H102.897L101.574 14.933Z"
          fill="#101113"
        />
        <path
          d="M113.356 35.61H100.622L105.73 8.29602H112.983L109.236 29.496H115.761L113.356 35.61Z"
          fill="#101113"
        />
        <path
          d="M137.352 35.61H130.006L129.931 31.303H124.748L123.148 35.61H116.152L125.978 8.29602H137.146L137.352 35.61ZM130.006 26.157L130.192 16.388H129.875L126.612 26.157H130.006Z"
          fill="#101113"
        />
        <path
          d="M160.823 27.742C159.891 33 156.609 35.61 150.979 35.61H140.203L145.013 8.29602H155.565C158.343 8.29602 160.319 8.85502 161.475 9.99602C162.631 11.137 162.985 12.923 162.556 15.384C162.366 16.57 161.903 17.6958 161.204 18.6729C160.506 19.6499 159.59 20.4516 158.529 21.015L158.492 21.276C160.637 22.167 161.42 24.33 160.823 27.742ZM153.533 19.371C153.963 18.716 154.231 17.9686 154.316 17.19C154.514 16.4682 154.501 15.7048 154.279 14.99C154.074 14.561 153.645 14.356 152.955 14.356H151.538L150.55 19.987H152.003C152.289 20.0098 152.575 19.966 152.841 19.8591C153.107 19.7522 153.344 19.5851 153.534 19.371H153.533ZM151.967 30.129C152.474 29.4002 152.782 28.5521 152.862 27.668C153.092 26.7998 153.085 25.8859 152.843 25.021C152.619 24.499 152.079 24.257 151.221 24.257H149.803L148.666 30.726H150.083C150.421 30.7578 150.762 30.7213 151.086 30.6185C151.41 30.5158 151.709 30.349 151.967 30.128V30.129Z"
          fill="#101113"
        />
      </svg>
    </div>
    <div className={styles.LogoMobile}>
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.999 35.8453C15.5588 35.851 13.1428 35.3633 10.8958 34.4115C8.73333 33.4977 6.772 32.167 5.12374 30.4954L8.52176 24.1801C8.78446 23.7099 9.168 23.3185 9.63282 23.0463C10.0976 22.7742 10.6269 22.6312 11.1655 22.6322H14.3094L16.4348 18.532H13.7007C13.5689 18.5323 13.4396 18.4981 13.3252 18.4326C13.2108 18.3671 13.1156 18.2727 13.0491 18.1589C12.981 18.046 12.9437 17.9172 12.9409 17.7854C12.9382 17.6536 12.9702 17.5234 13.0336 17.4078L14.4005 14.9777C14.4669 14.8584 14.5642 14.7589 14.6817 14.6893C14.7992 14.6198 14.933 14.5826 15.0696 14.5817H21.0382C21.1635 14.5817 21.2867 14.6128 21.397 14.6723C21.5073 14.7317 21.6013 14.8176 21.6703 14.9223C21.7404 15.0278 21.7831 15.149 21.7948 15.2752C21.8065 15.4013 21.7867 15.5283 21.7371 15.6448L16.5717 27.1878H26.0051L27.8422 22.9353L23.2702 22.9206L28.1584 10.9508C28.2357 10.7663 28.2661 10.5656 28.2467 10.3665C28.2274 10.1675 28.1587 9.97637 28.0474 9.81023C27.9345 9.64135 27.7817 9.50306 27.6023 9.40767C27.4229 9.31229 27.2227 9.26277 27.0195 9.26358H13.3041C12.9044 9.26588 12.5122 9.37227 12.166 9.57226C11.8199 9.77225 11.5319 10.059 11.3302 10.4041L2.09727 26.3952C0.719162 23.811 -0.00124786 20.9271 5.64741e-05 17.9984C-0.00588587 15.6341 0.45718 13.2921 1.36253 11.108C2.26787 8.92388 3.59762 6.941 5.27451 5.27419C6.94141 3.5974 8.92445 2.26788 11.1087 1.36257C13.2929 0.457262 15.6346 -0.0058415 17.999 5.56185e-05C20.3635 -0.00581435 22.7058 0.457317 24.8901 1.36262C27.0744 2.26792 29.0576 3.59742 30.7246 5.27419C32.4017 6.94093 33.7315 8.92378 34.637 11.1079C35.5425 13.292 36.0057 15.6341 35.9999 17.9984C36.0082 20.3519 35.5457 22.6833 34.6398 24.8554C33.7339 27.0276 32.4027 28.9967 30.7246 30.6469C27.3387 33.9967 22.7619 35.8662 17.999 35.8453Z"
          fill="#7CDEF4"
        />
      </svg>
    </div>
  </Link>
);

export default Logo;
